<template>
    <div>
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
        >
            <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
            >
                <b-row>
                    <b-col md="12">
                        <b-card>
                            <p class="text-center">{{$t('This tool helps you to provide a motivating offer to shoppers, by selecting two groups of products, the first (the first and second products) in which two products are to be selected to be purchased by the shopper, and the second group (the third product) contains a group of products from which the shopper can choose only one product, and get it for free or Discount at a specified percentage, or set a fixed price for products')}}.</p>
                            <p class="text-center">
                                <a
                                    :href="`https://${$store.state.store.url}/buy-two-get-one`"
                                    target="_blank"
                                >
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="primary"
                                        
                                    >
                                        {{$t('Page Url')}} <feather-icon icon="ExternalLinkIcon" />
                                    </b-button>        
                                </a>
                            </p>
                            <p><a href="https://cdn.mhd.sa/asset/images/buy-two-get-one.png" target="_blank"><b-img src="https://cdn.mhd.sa/asset/images/buy-two-get-one.png" thumbnail width="300" center /></a></p>
                            <hr>
                            <b-form @submit.prevent="save">
                                
                                <b-row>
                                    <!-- Is Active -->
                                    <b-col cols="12">
                                        <b-form-group
                                        :label="$t('Is Active ?')"
                                        label-for="v-is-active"
                                        >
                                            <b-form-checkbox
                                                id="v-is-active"
                                                class="custom-control-primary"
                                                v-model="buyTwoGetOne.is_active"
                                                name="is_active"
                                                switch
                                            >
                                                <span class="switch-icon-left">
                                                    <feather-icon icon="CheckIcon" />
                                                </span>
                                                <span class="switch-icon-right">
                                                    <feather-icon icon="XIcon" />
                                                </span>
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Third product Price -->
                                    <b-col cols="12">
                                        <b-form-group
                                            :label="$t('How do you want to apply discount on third product?')"
                                            label-for="v-is-active"
                                        >
                                            <div class="demo-inline-spacing">
                                                <b-form-radio
                                                    v-model="buyTwoGetOne.discount_type"
                                                    name="type"
                                                    value="free"
                                                >
                                                    {{$t('Free')}}
                                                </b-form-radio>
                                                <b-form-radio
                                                    v-model="buyTwoGetOne.discount_type"
                                                    name="type"
                                                    value="percentage"
                                                >
                                                    {{$t('Percentage')}}
                                                </b-form-radio>
                                                <b-form-radio
                                                    v-model="buyTwoGetOne.discount_type"
                                                    name="type"
                                                    value="fixed"
                                                >
                                                    {{$t('Fixed Price')}}
                                                </b-form-radio>
                                            </div>
                                        </b-form-group>
                                    </b-col>

                                    <!-- free -->
                                    <b-col cols="12" sm="5" v-if="buyTwoGetOne.discount_type == 'free'">
                                        <p class="text-danger">{{$t('All third products will be free')}}</p>
                                    </b-col>

                                    <!-- Discount Percentage -->
                                    <b-col cols="12" sm="5" v-if="buyTwoGetOne.discount_type == 'percentage'">
                                        <b-form-group
                                        label-for="v-discount-percentage"
                                        :description="$t('Discount percentage will apply on all third products')"
                                        >
                                            <b-input-group>
                                                <b-form-input
                                                    type="number"
                                                    v-model="buyTwoGetOne.discount_percentage"
                                                />
                                                <b-input-group-append is-text>
                                                %
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Fixed Discount -->
                                    <b-col cols="12" sm="5" v-if="buyTwoGetOne.discount_type == 'fixed'">
                                        <b-form-group
                                        label-for="v-fixed-price"
                                        :description="$t('Fixed price will apply on all third products')"
                                        >
                                            <b-input-group>
                                                <b-form-input
                                                    type="number"
                                                    v-model="buyTwoGetOne.fixed_discount"
                                                />
                                                <b-input-group-append is-text>
                                                    {{$t('SAR')}}
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                    <hr>
                                </b-row>

                                <b-row>
                                    <!-- First & Second Products -->
                                    <b-col cols="12" sm="6">
                                        <b-card 
                                            border-variant="primary"
                                            bg-variant="transparent"
                                            :title="$t('First & Second Product')"
                                            :sub-title="$t('Select the products that will be the first & the second that will shopper buy it')"
                                        >
                                            <!-- Title -->
                                            <b-form-group
                                                :label="$t('List Title')"
                                            >
                                                <b-form-input
                                                    v-model="buyTwoGetOne.first_second_list_title"
                                                    type="text"
                                                    :placeholder="$t('Buy Any Two Products')"
                                                />
                                            </b-form-group>

                                            <!-- Description -->
                                            <b-form-group
                                                :label="$t('List Description')"
                                            >
                                                <b-form-input
                                                    v-model="buyTwoGetOne.first_second_list_description"
                                                    type="text"
                                                    :placeholder="$t('Buy two products or two quantity from one product')"
                                                />
                                            </b-form-group>

                                            <!-- Products -->
                                            <b-form-group
                                                :label="$t('Products') + ` (${$t('Can not select products that has options')})`"
                                            >
                                                <v-select
                                                    v-model="first_second_products"
                                                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                                    label="name"
                                                    :options="products"
                                                    multiple
                                                >
                                                    <template #option="{ name, largeThumbnail }">
                                                        <b-img :src="largeThumbnail" width="50" height="50" />
                                                        <span> {{ name }}</span>
                                                    </template>
                                                </v-select>
                                                <div class="media-list media-bordered">
                                                    <template v-for="(first_second_product, indexOP) in first_second_products">
                                                        <b-media :key="indexOP">
                                                            <template #aside>
                                                                <b-img
                                                                    :src="first_second_product.largeThumbnail"
                                                                    blank-color="#ccc"
                                                                    width="64"
                                                                />
                                                            </template>
                                                            <h5 class="media-heading">
                                                                {{ first_second_product.name }}
                                                            </h5>
                                                            <b-card-text class="text-primary">
                                                                {{first_second_product.finalPrice}} {{$t('SAR')}}
                                                            </b-card-text>
                                                        </b-media>
                                                    </template>
                                                </div>
                                            </b-form-group>
                                        </b-card>
                                    </b-col>

                                    <!-- Third Products -->
                                    <b-col cols="12" sm="6">
                                        <b-card 
                                            border-variant="success"
                                            bg-variant="transparent"
                                            :title="$t('Third Product')"
                                            :sub-title="$t('Select the products that will be the third product that have an offer')"
                                        >
                                            <!-- Title -->
                                            <b-form-group
                                                :label="$t('List Title')"
                                            >
                                                <b-form-input
                                                    v-model="buyTwoGetOne.third_list_title"
                                                    type="text"
                                                    :placeholder="$t('Third Product')"
                                                />
                                            </b-form-group>

                                            <!-- Description -->
                                            <b-form-group
                                                :label="$t('List Description')"
                                            >
                                                <b-form-input
                                                    v-model="buyTwoGetOne.third_list_description"
                                                    type="text"
                                                    :placeholder="$t('Choice one product for free')"
                                                />
                                            </b-form-group>

                                            <!-- Products -->
                                            <b-form-group
                                                :label="$t('Products') + ` (${$t('Can not select products that has options')})`"
                                            >
                                                <v-select
                                                    v-model="third_products"
                                                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                                    label="name"
                                                    :options="products"
                                                    multiple
                                                >
                                                    <template #option="{ name, largeThumbnail }">
                                                        <b-img :src="largeThumbnail" width="50" height="50" />
                                                        <span> {{ name }}</span>
                                                    </template>
                                                </v-select>
                                                <div class="media-list media-bordered">
                                                    <template v-for="(third_product, indexOP) in third_products">
                                                        <b-media :key="indexOP">
                                                            <template #aside>
                                                                <b-img
                                                                    :src="third_product.largeThumbnail"
                                                                    blank-color="#ccc"
                                                                    width="64"
                                                                />
                                                            </template>
                                                            <h5 class="media-heading">
                                                                {{ third_product.name }}
                                                            </h5>
                                                            <b-card-text>
                                                                <span class="text-lined">{{third_product.finalPrice}} {{$t('SAR')}}</span> <span class="text-danger">{{priceAfterApplyOffer(third_product.finalPrice)}}</span>
                                                            </b-card-text>
                                                        </b-media>
                                                    </template>
                                                </div>
                                            </b-form-group>
                                        </b-card>
                                    </b-col>
                                </b-row>

                                <!-- submit -->
                                <b-row>
                                    <b-col cols="12">
                                        <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        type="submit"
                                        variant="primary"
                                        class="mr-1 mt-1"
                                        >
                                            <template v-if="!isLoadingSave">
                                                {{$t('Save')}}
                                            </template>
                                            <template v-if="isLoadingSave">
                                                {{$t('Saveing')}}...
                                                <b-spinner small />
                                            </template>
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-card>
                    </b-col>
                </b-row>
            </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
export default {
    directives: {
        Ripple,
    },
    mounted() {
        this.getProducts().then(() => {
            this.getResults();
        });
    },
    data() {
        return {
            buyTwoGetOne: {
                is_active: false
            },
            isLoadingSave: false,
            isLoading: true,
            products: [],
            first_second_products: [],
            third_products: []
        }
    },
    methods: {
        getResults() {
            useJwt.post('/store/config/get-by-id',{key: 'buy_two_get_one'})
            .then((response) => {
                // console.log(response.data)
                if (response.data.data && Object.keys(response.data.data).length) {
                    this.buyTwoGetOne = response.data.data.buy_two_get_one

                    // put products at array for select input
                    if(this.buyTwoGetOne.first_second_products_ids.length) {
                        this.buyTwoGetOne.first_second_products_ids.forEach(id => {
                            this.first_second_products.push(this.products.find(product => { return product.id == id }))
                        });
                    }
                    if(this.buyTwoGetOne.third_products_ids.length) {
                        this.buyTwoGetOne.third_products_ids.forEach(id => {
                            this.third_products.push(this.products.find(product => { return product.id == id }))
                        });
                    }
                }
                this.isLoading = false
            })
            .catch(response => {
                console.log(response);
            });
        },
        save() {

            if (!this.validation()) {
                return;
            }

            this.isLoadingSave = true;
            this.buyTwoGetOne.store_id = this.$store.state.store.id;
            let form = this.buyTwoGetOne;

            // reset, don't need it in backend
            // form.first_second_products = null 
            // form.third_products = null

            useJwt.post('/marketing-offers/buy-two-get-one/save',form)
            .then((response) => {
                // console.log(response.data);
                this.isLoadingSave = false
                this.$root.showToast('success', 'save', '')
            })
            .catch(error => {
                // console.log(response);
                this.isLoadingSave = false
                this.$root.showAlert('error', error.response.data.message)
            });
        },
        async getProducts() {
            await useJwt.get('/products/get-all')
            .then((response) => {
                this.products = response.data.data
            })
            .catch(response => {
                // console.log(response);
            });
        },
        priceAfterApplyOffer(price) {
            if (this.buyTwoGetOne.discount_type == 'free')
            {
                return this.$t('Free');
            }
            if (this.buyTwoGetOne.discount_type == 'fixed')
            {
                return (this.buyTwoGetOne.fixed_discount || 0) + this.$t('SAR');
            }
            if (this.buyTwoGetOne.discount_type == 'percentage')
            {
                return Number(price - Number((price / 100) * (this.buyTwoGetOne.discount_percentage || 0))).toFixed(2) + this.$t('SAR');
            }
        },
        validation() {
            // discount_type
            if (!this.buyTwoGetOne.discount_type) {
                this.$swal({
                    title: this.$t('Error!'),
                    text: this.$t('Please Select Discount Type'),
                    icon: "error",
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
                return false;
            }
            
            // discount_percentage
            if (this.buyTwoGetOne.discount_type == "percentage") {
                if (!this.buyTwoGetOne.discount_percentage) {
                    this.$swal({
                        title: this.$t('Error!'),
                        text: this.$t('Please Select Discount Percentage'),
                        icon: "error",
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    return false;
                }
            }

            // fixed_discount
            if (this.buyTwoGetOne.discount_type == "fixed") {
                if (!this.buyTwoGetOne.fixed_discount) {
                    this.$swal({
                        title: this.$t('Error!'),
                        text: this.$t('Please Select Price After Discount'),
                        icon: "error",
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    return false;
                }
            }

            // first_second_list_title
            if (!this.buyTwoGetOne.first_second_list_title) {
                this.$swal({
                    title: this.$t('Error!'),
                    text: this.$t('Please Write First & Second List Title'),
                    icon: "error",
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
                return false;
            }

            // first_second_list_description
            if (!this.buyTwoGetOne.first_second_list_description) {
                this.$swal({
                    title: this.$t('Error!'),
                    text: this.$t('Please Write First & Second List Description'),
                    icon: "error",
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
                return false;
            }

            // third_list_title
            if (!this.buyTwoGetOne.third_list_title) {
                this.$swal({
                    title: this.$t('Error!'),
                    text: this.$t('Please Write Third List Title'),
                    icon: "error",
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
                return false;
            }

            // third_list_description
            if (!this.buyTwoGetOne.third_list_description) {
                this.$swal({
                    title: this.$t('Error!'),
                    text: this.$t('Please Write Third List Description'),
                    icon: "error",
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
                return false;
            }

            if (!this.first_second_products || Object.keys(this.first_second_products).length <= 0) {
                this.$swal({
                    title: this.$t('Error!'),
                    text: this.$t('Please Select Products At First & Second Product list'),
                    icon: "error",
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
                return false;
            }

            if (!this.third_products || Object.keys(this.third_products).length <= 0) {
                this.$swal({
                    title: this.$t('Error!'),
                    text: this.$t('Please Select Products At Third Product list'),
                    icon: "error",
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
                return false;
            }

            // get products ids
            this.buyTwoGetOne.first_second_products_ids = []
            this.first_second_products.forEach(product => {
                this.buyTwoGetOne.first_second_products_ids.push(product.id)
            });

            this.buyTwoGetOne.third_products_ids = []
            this.third_products.forEach(product => {
                this.buyTwoGetOne.third_products_ids.push(product.id)
            });

            // check if any product included at the two list
            this.buyTwoGetOne.third_products_ids.forEach(product_id => {
                if (this.buyTwoGetOne.first_second_products_ids.includes(product_id)) {
                    this.$swal({
                        title: this.$t('Error!'),
                        text: this.$t("It is not possible to add a product in both groups, the products in the two groups must be different"),
                        icon: "error",
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    return false;
                }
            });

            return true;
        }
    }
}
</script>

<style lang="sass" scoped>
    [dir] .demo-inline-spacing > *
        margin-top: 0
    
    [dir] .media-list .media
        padding: 1.25rem 0

    .text-lined 
        text-decoration: line-through
</style>
